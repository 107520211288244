nb-window {
    div.title {
        outline: none;
    }
}

$_width-sizes: (500, 600, 700, 800, 900, 1000, 1100, 1200);
@each $size in $_width-sizes {
    .width-#{$size}-responsive {
        width: #{$size}px;
        max-width: 100%;
    }
}
