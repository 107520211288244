$_statuses: (basic, primary, success, info, warning, danger, control);
@each $status in $_statuses {
    .notification-#{$status} {
        .content-container {
            display: block !important;
            .title.subtitle {
                display: none;
            }

            .message {
                display: block;
                overflow-wrap: anywhere;
            }
        }
    }
}

.notification-danger {
    .title.subtitle {
        display: block !important;
    }
}
