.icon-info-tooltip {
    font-size: 12px !important;
}

.attachment-icon {
    width: 35px;
    height: 37px;
    background-image: url('/assets/doc-types.png');
    background-repeat: no-repeat;
    background-position: -133px -118px;
}

@media (min-width: 1200px) {
    .attachment-icon {
        width: 45px;
        height: 47px;
        background-image: url('/assets/doc-types-2.png');
    }
}

.doc,
.docx,
.txt,
.xml {
    background-position: -133px -29px;
}

.xlsx,
.xls {
    background-position: -232px -29px;
}

.png,
.jpg,
.jpeg,
.jpe,
.bmp,
.tif,
.tiff,
.gif {
    background-position: -331px -29px;
}

.hl7 {
    background-position: -432px -29px;
}

.ccd {
    background-position: -531px -29px;
}

.pdf {
    background-position: -34px -118px;
}

.cda {
    background-position: -232px -118px;
}

.ppt,
.pptx {
    background-position: -331px -118px;
}

.lnk {
    background-position: -432px -118px;
}

.mov,
.avi,
.mpg,
.mpeg {
    background-position: -531px -118px;
}

.doc-many {
    background-position: -32px -29px;
}

.doc-none {
    opacity: 0.15;
}
