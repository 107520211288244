/* You can add global styles to this file, and also import other style files */
@use 'modals/window';
@use 'modals/dialog';
@use 'icons/icons';
@use 'accordions/card-accordion';
@use 'notifications/notifications';
@use 'overlays/overlays';
@use 'cell-renderer/cell-renderer';
@use 'grid/grid';
@use 'context-menu/context-menu';
@use 'sizing/sizing';

@import 'theming/theme';
@import 'forms/form-control';

@include nb-install() {
    @include nb-theme-global();
}
