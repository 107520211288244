@import '@nebular/theme/styles/theming';
@import '@nebular/theme/styles/themes/default';
@import '@nebular/theme/styles/globals';

@import 'functions';
@import 'colors';
@import 'effects';

html {
    font-size: 13px;
}

$nb-enable-css-custom-properties: true;

$custom-brand-theme: (
    custom-card-shadow: $custom-card-shadow,
    custom-toolbar-shadow: $custom-toolbar-shadow,
    custom-color-secondary: nb-theme(sidebar-background-color),
    link-text-color: nb-theme(color-primary-default),
    tooltip-max-width: '100vw',
    select-min-width: 0
) !default;

$custom-content-map: map-collect($custom-content, $custom-text, $custom-status, $custom-brand-theme, $custom-message-status);
$nb-themes: nb-register-theme($custom-content-map, default, default);

@include nb-install() {
    // framework global styles
    @include nb-theme-global();
}

/*
* Fixes sidebar issues when enabling cussom css
* https://github.com/akveo/nebular/issues/1911#issue-481882914
*/
nb-sidebar nb-menu {
    margin: 0 calc(-1 * #{nb-theme(sidebar-padding)}) calc(-1 * #{nb-theme(sidebar-padding)});
}

nb-popover {
    z-index: 999 !important;
}

nb-spinner {
    z-index: 978 !important;
}
